// 封装操作localstorage本地存储的方法  模块化

const storage = {
  set(key, value) {
    localStorage.setItem(key, JSON.stringify(value))
  },
  get(key) {
    if (localStorage.getItem(key) != undefined) {
      const storages = localStorage.getItem(key)
      if (storages != null) return JSON.parse(storages)
    }
  },
  getForIndex(index) {
    return localStorage.key(index)
  },
  getKeys() {
    const items = this.getAll()
    const keys = []
    for (let index = 0; index < items.length; index++) {
      keys.push(items[index].key)
    }
    return keys
  },
  getLength() {
    return localStorage.length
  },
  getSupport() {
    return typeof Storage !== 'undefined' ? true : false
  },
  remove(key) {
    localStorage.removeItem(key)
  },
  removeAll() {
    localStorage.clear()
  },
  getAll() {
    const len = localStorage.length // 获取长度
    const arr = new Array() // 定义数据集
    for (let i = 0; i < len; i++) {
      // 获取key 索引从0开始
      const getKey = localStorage.key(i)
      // 获取key对应的值
      if (getKey != null) {
        const getVal = localStorage.getItem(getKey)
        // 放进数组
        arr[i] = {
          key: getKey,
          val: getVal
        }
      }
    }
    return arr
  }
}

export default storage
