import storage from '@/model/storage'
import {
  createRouter,
  createWebHashHistory
} from 'vue-router'
//默认进入页面
const _default = "/iconme/OrderList"
const routes = [{
    path: '/',
    redirect: _default,
  },
  {
    path: '/home',
    component: () => import('../views/HomeView.vue'),
    meta: {
      requireAuth: true
    },
    redirect: _default,
    children: [{
        path: '/index',
        component: () => import('../views/index/IndexView.vue')
      },
      //引流订单列表
      {
        path: '/iconme/OrderList',
        component: () => import('../views/income/OrderList.vue')
      },
      //引流收支明细
      {
        path: '/iconme/IncomeList',
        component: () => import('../views/income/IncomeList.vue')
      }
    ]
  },
  {
    path: '/login',
    component: () => import('../views/LoginView.vue')
  },
  {
    // 匹配为定义路由然后重定向到404页面
    path: '/:pathMath(.*)',
    redirect: '/404'
  },
  {
    // 定义404路由
    path: '/404',
    component: () => import('../views/NotfoundView.vue')
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})
router.beforeEach((to, from, next) => {
  if (to.matched.some((r) => r.meta.requireAuth)) {
    if (storage.get('token')) {
      next() //有token,进行request请求，后台还会验证token
    } else {
      next({
        path: '/login'
      })
    }
  } else {
    next() //如果无需token,那么随它去吧
  }
})

export default router