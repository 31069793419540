<template>
  <el-pagination
      v-model:currentPage="page.page"
      v-model:page-size="page.size"
      :page-sizes="[10, 20, 40, 80]"
      :small="small"
      :disabled="disabled"
      :background="background"
      layout="total, sizes, prev, pager, next, jumper"
      :total="page.total"
      @size-change="getList"
      @current-change="getList"
  />
</template>
<script setup>
import {defineProps, ref,defineEmits} from "vue"
const props=defineProps({page:{type:Object}})
const page =ref(props.page)
const emits=defineEmits(['getList'])
const getList=()=>{
  emits("getList")
}
</script>