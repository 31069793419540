import { createApp } from 'vue'
import App from './App.vue'
import router from './router'

import ElementPlus from 'element-plus'
import zhCn from 'element-plus/es/locale/lang/zh-cn'
import 'element-plus/dist/index.css'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
import Bread from '@/components/BreadcrumbWord.vue';
import PageWord from '@/components/PageWord.vue';

const app = createApp(App)
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component)
}
app.use(ElementPlus, {
  locale: zhCn
})
app.component('This_Bread',Bread)
app.component('PageWord',PageWord)



app.use(router).mount('#app')
import { ajax } from '@/model/request'
window.Ajax = ajax

import { Message as message } from '@/model/showToast'
window.Message = message