import { ElMessage, ElLoading, ElMessageBox } from 'element-plus'

/**
 * 消息提示
 */
export let Message = {
  /**
   *
   * @param {*} type success warning error
   * @param {*} message  内容
   */
  show: (type, message) => {
    ElMessage({
      showClose: true,
      message: message,
      type: type
    })
  },
  success: (message) => {
    ElMessage({
      showClose: true,
      message: message,
      type: 'success'
    })
  },
  error: (message) => {
    ElMessage({
      showClose: true,
      message: message,
      type: 'error'
    })
  },
  warning: (message) => {
    ElMessage({
      showClose: true,
      message: message,
      type: 'warning'
    })
  },
  showTost: (data) => {
    if (data.code == 0) {
      if(data.msg){
        Message.success(data.msg)
      }else{
        Message.success(data.ret_msg)
      }
      return true
    } else {
      Message.error(data.msg)
      return false
    }
  },
  bools: (data) => {
    if (data.code == 0) {
      return true
    } else {
      Message.error(data.msg)
      return false
    }
  },
  trueTost: (data) => {
    if (data.code == 0) {
      return true
    } else {
      if(data.msg){
        Message.error(data.msg)
      }else{
        Message.error(data.ret_msg)
      }
      return false
    }
  }
}

let loadBox
/**
 * 加载框
 */
export const Loading = {
  showLoad: () => {
    loadBox = ElLoading.service({
      lock: true,
      text: 'Loading',
      background: 'rgba(0, 0, 0, 0.7)'
    })
  },
  hideLoad: () => {
    loadBox.close()
  }
}
export const MessageBox = {
  showInput: (title, label, callback = () => { }, errback = () => { }) => {
    ElMessageBox.prompt(label, title, {
      confirmButtonText: '确认',
      cancelButtonText: '取消'
    })
      .then(({ value }) => {
        callback(value)
      })
      .catch(() => {
        errback()
      })
  },
  showMessage: (title, label, callback = () => { }, errback = () => { }) => {
    ElMessageBox.confirm(label, title, {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
    })
      .then(() => {
        callback()
      })
      .catch(() => {
        errback()
      })
  },
  showMessageValue: (title, label, VALUE, callback = () => { }, errback = () => { }) => {
    ElMessageBox.prompt(label, title, {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      inputValue: VALUE
    })
      .then(({ value }) => {
        callback(value)
      })
      .catch(() => {
        errback()
      })
  }
}
